html:is([data-theme="dark"]) {
	.p-datatable {
		border: 1px solid rgba(48, 69, 98);
		border-width: 0 0.05rem 0rem 0.05rem;		
		.p-datatable-header {
			border: 1px solid rgba(48, 69, 98);
			border-width: 0.05rem 0px 0px 0px	
		}
		.p-datatable-footer {
			border-width: 0 0 0.05rem 0.05rem;
			border: 1px solid rgba(48, 69, 98);
		}
		.p-datatable-tfoot {
			>tr {
				>td {
					border-width: 0 0 0.05rem 0.05rem;
					border: 1px solid rgba(48, 69, 98);
				}
			}
		}
		
		.p-datatable-thead {
			>tr {
				>th {
					text-align: center;
					border-width: 0 0 0.05rem 0.05rem;
					border: 1px solid rgba(48, 69, 98);
				}
			}
		}

		&:has(.p-datatable-thead) {
			&:has(.p-datatable-tbody) {
				.p-datatable-tbody {
					>tr {
						>td {
							//text-align: center;
							border-width: 0.05rem 0.05rem 0.05rem 0.05rem;
							border: 1px solid rgba(48, 69, 98);
						}
					}
				}
			}
		}

		td input[type="checkbox"] {
			float: left;
			margin: 0 auto;
			width: 100%;
		}

		.p-paginator {
			border: 1px solid rgba(48, 69, 98);	
			border-width: 1px 0px 1px 0px
		}
		
	}
	
	.p-toolbar {
		border: 1px solid rgba(48, 69, 98);
		border-radius: 4px;
	}

	.p-picklist {
		.p-picklist-list {
			.p-picklist-item.p-highlight {
				color: rgba(255, 255, 255, 0.87);
    			background: rgba(100, 181, 246, 0.16);
				//box-shadow: 0 5px 15px rgb(0 0 0 / 10%)
			}
		}
	}
}

html:is([data-theme="light"]) {	
	.p-datatable {
		border: 1px solid rgba(70, 77, 242, 0.2);
		border-width: 0 0.05rem 0rem 0.05rem;		
		.p-datatable-header {
			border: 1px solid rgba(70, 77, 242, 0.2);	
			border-width: 0.05rem 0px 0px 0px	
		}
		.p-datatable-footer {
			border-width: 0 0 0.05rem 0.05rem;
			border: 1px solid rgba(70, 77, 242, 0.2);
		}
		.p-datatable-tfoot {
			>tr {
				>td {
					border-width: 0 0 0.05rem 0.05rem;
					border: 1px solid rgba(70, 77, 242, 0.2);
				}
			}
		}
		
		.p-datatable-thead {
			>tr {
				>th {
					text-align: center;
					border-width: 0 0 0.05rem 0.05rem;
					border: 1px solid rgba(70, 77, 242, 0.2);
				}
			}
		}

		&:has(.p-datatable-thead) {
			&:has(.p-datatable-tbody) {
				.p-datatable-tbody {
					>tr {
						>td {
							//text-align: center;
							border-width: 0.05rem 0.05rem 0.05rem 0.05rem;
							border: 1px solid rgba(70, 77, 242, 0.2);							
						}
					}
				}
			}
		}

		td input[type="checkbox"] {
			float: left;
			margin: 0 auto;
			width: 100%;
		}

		.p-paginator {
			border: 1px solid rgba(70, 77, 242, 0.2);		
			border-width: 1px 0px 1px 0px
		}
		
	}
	
	.p-toolbar {
		border: 1px solid rgba(70, 77, 242, 0.2);
		border-radius: 4px;
	}

	.p-picklist {
		.p-picklist-list {
			.p-picklist-item.p-highlight {
				color: #495057;
				background: #E3F2FD;
				//box-shadow: 0 5px 15px rgb(0 0 0 / 10%)
			}
		}
	}

	.p-dialog .p-dialog-header{
		border-bottom: 1px solid #dee2e6;
		background: #f4f4f4;
		padding: 0.3rem;
	}
	
	.p-dialog .p-dialog-header .p-dialog-header-icon {
		background: url("../../../media/image/lightbox-close@3x.png") center / 30px 30px
			transparent no-repeat;
		border: none ;
		cursor: pointer;
		font-size: 0;
		height: 40px;
		//position: absolute;
		width: 40px;
		color: #f4f4f4;
	}
	
	.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
		background: url("../../../media/image/lightbox-close@3x.png") center / 30px 30px
			transparent no-repeat;
		border: none ;
		cursor: pointer;
		font-size: 0;
		height: 40px;		
		width: 40px;			
	}
	
}

.primeng-no-data {
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.p-dialog{
	overflow: hidden;   	
	box-shadow: 1px 1px 10px 0px;
    border-radius: 0.625rem;
}

.p-button.p-button-icon-only.p-button-rounded {	
	height: 2rem;
  }

  .p-button.p-button-icon-only {
    width: 2rem;	
}

@media screen and (max-width: 640px)
{
	.p-dialog{
		right: 7px;
	}
}