@import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
@import "./assets/sass/style.angular.scss";

//@import "../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css";

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
@import "./assets/primeng/datatable/css/primeng.datatable.scss";
/*html,
body {
    font-size: small !important;
}*/
.p-picklist-item:not(.cdk-drag-disabled) {
    cursor: move;
    z-index: 1130 !important;
  }
